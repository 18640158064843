.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App .content {
  width: min(1000px, 90vw);
  border: 1px solid rgba(220, 220, 220, 0.5);
  border-radius: 5px;
  padding: 25px;
  margin-top: 100px;
}
